<template>
    <div class="container container-width">
        <div class="text-end mt-2" v-if="isNavigationAllowed(NAVIGATIONCONST.CREATEVISIT)">
            <Button class="button-color-lavender-purple create-new-button" label="CREATE NEW VISIT" @click="showHideVisitForm" />
        </div>
        <DataTable
            ref="dt"
            :paginator="true"
            :rows="rows"
            :rowsPerPageOptions="rowPerPageOptions"
            :totalRecords="totalRecords"
            :lazy="true"
            :value="visits"
            class="p-0 mt-3"
            v-model:first="lazyParams.first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            v-model:selection="selectedVisit"
            selectionMode="single"
            dataKey="id"
            :loading="loading"
            sortField="title"
            :sortOrder="-1"
            @page="onPage($event)"
            @sort="onPage($event)"
        >
            <template #header>
                <Button class="p-button-text" @click="openFilter()">
                    <img src="../../../../assets/filter-icon.png" />
                    <span class="ms-2 color-lavender-purple">Filter</span>
                </Button>
            </template>
            <template #empty> No visits found. </template>
            <Column field="title" header="Visit Name" class="title-column" :sortable="!isMyVisitRoute &&
             !isLocationFilterSelected && !isDateFilterSelected">
                <template #body="{ data }">
                    <span class="legend-key ms-0" :class="visitStatusColor[data.state]"></span>
                    <span
                        class="table-first-column"
                        @click="onTitleClick(data)"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="data.title"
                    >
                        {{ data.title }}
                    </span>
                </template>
            </Column>
            <Column field="visitLocationInfo.boroughId" header="Borough" :sortable="!isMyVisitRoute &&
             !isLocationFilterSelected && !isDateFilterSelected">
                <template #body="{ data }">
                    {{ data.visitLocationInfo ? data.visitLocationInfo.boroughId : NA }}
                </template>
            </Column>
            <Column field="visitLocationInfo.districtName" header="District" :sortable="!isMyVisitRoute &&
             !isLocationFilterSelected && !isDateFilterSelected">
                <template #body="{ data }"> {{ data.visitLocationInfo?.districtId }}: {{ data.visitLocationInfo?.districtName }} </template>
            </Column>
            <Column field="visitLocationInfo?.buildingName" header="Building">
                <template #body="{ data }"> {{ data.visitLocationInfo?.buildingId }}: {{ data.visitLocationInfo?.buildingName }} </template>
            </Column>
            <Column field="coveredLocations.length" header="Locations" />
            <Column field="schedule.start" header="Start Date" :sortable="!isMyVisitRoute &&
             !isLocationFilterSelected || (isDateFilterSelected && !isLocationFilterSelected)">
                <template #body="{ data: { schedule } }">
                    {{ Utility.formateDate(schedule?.start?.seconds) }}
                </template>
            </Column>
            <Column field="schedule" header="Duration">
                <template #body="{ data: { schedule } }">
                    {{
                        schedule?.start?.seconds && schedule.end?.seconds
                            ? Utility.getDuration(schedule.start.seconds, schedule.end.seconds) + ' days'
                            : 'N/A'
                    }}
                </template>
            </Column>
            <Column field="teams.length" header="Team" />
            <Column header="Action" v-if="isNavigationAllowed(NAVIGATIONCONST.ALLVISITS)">
                <template #body="{ data }">
                    <router-link
                        v-if="VisitStatus.Cancelled != data.state"
                        label="Analysis"
                        class="table-first-column"
                        :to="{ name: NAVIGATIONCONST.VISITANALYSIS, params: { id: data.id } }"
                    >Analysis</router-link>
                </template>
            </Column>
            <template #paginatorstart></template>
        </DataTable>
        <CreateVisit v-if="showModal" @toggelModal="showHideVisitForm" :visitData="visit" :boroughs="boroughList"
        :districtList="districtList" />
        <VisitDetails v-else-if="showDetails" @toggelModel="onShowDetails" :visitById="visit" />
        <VisitsFilter :displayFilter="displayFilter" :teams="teams" :isMyVisitRoute="isMyVisitRoute"
         @applyFilter="applyFilter" @closeFilter="closeFilter" />
    </div>
</template>
<script>
import _cloneDeep from 'lodash/cloneDeep';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { FilterMatchMode } from 'primevue/api';
import { ref } from 'vue';
import CreateVisit from '../create-visit';
import { Timestamp } from '../../../../../firebase';
import VisitDetails from '../visit-details/VisitDetails.vue';
import Utility from '../../../../shared/utility/utility';
import { NAVIGATIONCONST, messages, rows, NA, rowPerPageOptions, toasterTime, visitStatusColor, filterMatchMode } from '../../../../shared/constants';
import { isNavigationAllowed } from '../../../../shared/utils/auth-utils';
import VisitsFilter from './VisitsFilter.vue';
import { BoroughService, DistrictService, VisitService, TeamService, UserService } from '../../../../apis';
import { VisitStatus } from '../../../../shared/enums';

export default {
    name: 'visits',
    components: {
        Button,
        Column,
        DataTable,
        VisitDetails,
        VisitsFilter,
        CreateVisit
    },
    data() {
        return {
            isMyVisitRoute: false,
            VisitStatus,
            NA,
            Utility,
            visitStatusColor,
            rows,
            rowPerPageOptions,
            isNavigationAllowed,
            NAVIGATIONCONST,
            visits: [],
            visit: null,
            selectedVisit: null,
            loading: false,
            lazyParams: {},
            totalRecords: ref(0),
            showDetails: false,
            showModal: false,
            displayFilter: false,
            isLocationFilterSelected: false,
            isDateFilterSelected: false,
            filters: {
                'state': { value: null, matchMode: FilterMatchMode.EQUALS },
                'visitLocationInfo.buildingId': { value: [], matchMode: FilterMatchMode.IN },
                'teams': { value: null, matchMode: filterMatchMode.arrayContains },
                'schedule.start': { value: null, matchMode: filterMatchMode.rangeOperator }
            },
            teams: []
        };
    },
    async mounted() {
        const filtersCond = {
            todaysVisit: {
                'schedule.end': { value: Timestamp.now(), matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
                ...this.filters
            },
            myVisits: {
                uniqueTeamMembers: { value: this.$store.getters.getSessionUser.uid, matchMode: 'array-contains' },
                ...this.filters
            },
            visits: {
                ...this.filters
            },
            ourVisits: {
                visitLocationId: { value: await UserService.getBuildingManagerVisitLocations(this.$store.getters.getSessionUser.uid), matchMode: 'in' },
                ...this.filters
            }
        };

        const route = this.$route;
        this.isMyVisitRoute = route.name == NAVIGATIONCONST.TODAYSVISITS;

        this.filters = filtersCond[this.$route.fullPath.split('/')[1]];
        if (!this.filters) {
            return;
        }
        this.lazyParams = {
            page: 0,
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: 'title',
            sortOrder: -1,
            filters: this.filters
        };
        this.fetchVisits(this.lazyParams);
        this.getTotalRecords(this.lazyParams);
    },
    methods: {
        getTotalRecords() {
            this.loadingCount = true;
            this.setLoading();
            VisitService.getSnapshotCount(this.filters)
                .then(value => (this.totalRecords = value))
                .then(() => setTimeout(() => (this.loadingCount = false), toasterTime));
        },
        async fetchVisits(params) {
            this.loadingData = true;
            this.setLoading();
            try {
                this.loading = true;
                this.visits = await VisitService.getSnapshot(params);
            } catch (err) {
                console.log(err);
                this.$toast.add({
                    severity: 'error',
                    detail: messages.visitValidation.visitsFetchingFailed,
                    closable: false,
                    life: toasterTime
                });
            } finally {
                setTimeout(() => (this.loadingData = false), toasterTime);
            }
        },
        setLoading() {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                this.loading = this.loadingData || this.loadingCount;
                this.loading || clearInterval(this.interval);
            }, 100);
        },
        onPage(event) {
            this.lazyParams = event;
            this.lazyParams.filters = this.filters;
            this.fetchVisits(this.lazyParams);
        },
        async getAllBorough() {
            this.boroughList = this.boroughList || (await BoroughService.getAll({ isInScope: { matchMode: '==', value: 1 } }));
        },
        async getAllDistricts() {
            this.districtList = this.districtList || (await DistrictService.getAll({ isInScope: { matchMode: '==', value: 1 } }));
        },
        async showHideVisitForm() {
            if (!this.showModal) {
                await Promise.all([this.getAllBorough(), this.getAllDistricts()]);
            }
            this.showModal = !this.showModal;
            this.visit = null;
            this.selectedVisit = null;
        },
        async editVisit(visitData) {
            this.visit = visitData;
            if (!this.showModal) {
                await Promise.all([this.getAllBorough(), this.getAllDistricts()]);
            }
            this.showModal = !this.showModal;
        },
        async onShowDetails(visitData) {
            this.showDetails = !this.showDetails;
            this.visit = visitData;
        },
        async getTeamList() {
            if (!this.teams.length) {
                const res = await TeamService.getAll();
                this.teams = res.sort((a, b) => a.name.localeCompare(b.name));
            }
        },
        applyFilter(state, filters) {
            const team = {
                members : filters.team?.members,
                name : filters.team?.name,
                teamId : filters.team?.id,
                team_lead : filters.team?.team_lead
            }
            const buildings = filters.locations?.buildings;
            this.isLocationFilterSelected = buildings?.length > 0;
            this.isDateFilterSelected = Object.keys(filters.date)?.length > 0;
            this.filters['teams'].value = filters.team ? team : null;
            this.filters.state.value = state;
            this.filters['visitLocationInfo.buildingId'].value = buildings;
            this.filters['schedule.start'].value = [filters.date?.fromDate, filters.date?.uptoDate];
            this.lazyParams.filters = this.filters;
            this.lazyParams.first = 0;
            this.lazyParams.page = 0;
            this.fetchVisits(this.lazyParams);
            this.getTotalRecords(this.lazyParams);
            this.closeFilter();
        },
        openFilter() {
            this.getTeamList();
            this.displayFilter = true;
        },
        closeFilter() {
            this.displayFilter = false;
        },
        onTitleClick(visitData) {
            if (isNavigationAllowed(NAVIGATIONCONST.EDITVISIT)) {
                const data = _cloneDeep(visitData);
                this.editVisit(data);
            } else if (isNavigationAllowed(NAVIGATIONCONST.VIEWVISIT)) {
                this.onShowDetails(visitData);
            }
        }
    }
};
</script>

<style>
.title-column {
    max-width: 21rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
