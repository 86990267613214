<template>
    <Dialog header="Filter" @hide="$emit('closeFilter')" position="topright" v-model:visible="displayFilter"
    :style="{width: '25.25rem'}" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500 mb-2">Status</div>
        <div class="field-checkbox margin-top-12 mb-4">
           <Dropdown class="w-100" v-model="selectedStatus" :options="VisitStatus"
           optionLabel="name" optionValue="code" placeholder="Select a State" />
        </div>
        <div v-if="isMyVisitRoute==false">
        <div class="text-muted fs-5 font-weight-500 mb-2">Teams</div>
        <div class="field-checkbox margin-top-12 mb-4">
           <Dropdown class="w-100" v-model="selectedTeam" :options="teams" optionLabel="name"
            placeholder="Select a Team" >
               <template #option="slotProps">
               <div class="width-dropdown-item">{{slotProps.option.name}}</div>
           </template>
           </Dropdown>
        </div>
        <div class="text-muted fs-5 font-weight-500 mb-2">Date Range</div>
        <div class="field-checkbox margin-top-12 mb-4">
            <Calendar class="w-100" id="icon" inputId="range" v-model="dateRange" selectionMode="range" :showIcon="true"
            placeholder="Select Date" :disabledDays="[0, 6]" dateFormat="mm/dd/y" :manualInput="false" />
        </div>
        </div>
        <div class="text-muted fs-5 font-weight-500 mb-2">Locations</div>
        <div class="field-checkbox margin-top-12">
            <LocationSelection :showTree="true" v-on:selectTree="applyTree" :selectedLocations="selectedLocations"/>
        </div>
        <Button class="filterText apply-button-size justify-content-center button-color-lavender-purple mt-4 w-100"
        @click="applyfilter()">APPLY</Button>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { VisitStatus } from '../../../../shared/enums/visit-status';
import LocationSelection from '../../../../shared/LocationSelection.vue';
import Utility from '../../../../shared/utility/utility';
export default {
    name: 'VisitsFilter',
    components: {
        Button,
        Dialog,
        Dropdown,
        Calendar,
        LocationSelection
    },
    props: {
        displayFilter: Boolean,
        teams: Array,
        isMyVisitRoute: Boolean
    },
    data() {
        return {
            selectedStatus: null,
            selectedTeam: null,
            VisitStatus: Object.keys(VisitStatus)
                .map(key => ({ name: String(key), code: VisitStatus[key] })),
            dateRange: null,
            dateObj: {},
            selectedLocations: [],
            filterObj: {}
        };
    },
    methods: {
        clearFilters() {
            this.selectedStatus=null;
            this.selectedTeam='';
            this.dateRange= null;
            this.dateObj = {};
            this.selectedLocations = [];
            this.filterObj = {};
            this.applyfilter();
        },
        applyfilter() {
            if (this.dateRange) {
                let [fromDate, uptoDate] = this.dateRange;
                uptoDate = uptoDate && Utility.getEndOfDayDate(uptoDate);
                this.dateObj = { fromDate, uptoDate };
            }
            this.filterObj.date = this.dateObj;
            this.filterObj.team = this.selectedTeam;
            this.$emit('applyFilter', parseInt(this.selectedStatus), this.filterObj);
        },
        applyTree(selections, filterObj) {
            this.selectedLocations = selections;
            this.filterObj.locations = filterObj;
        }
    }
};
</script>


