<template>
    <Dialog
        modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        contentClass="pb-0"
        v-model:visible="showModel"
        :closable="false"
        :style="{ width: '80vw' }"
    >
        <div class="container-fluid">
            <div class="row fw-normal">
                <div class="col-md-8">{{ visit.title }}</div>
                <div class="col-md-4 text-end fw-bold">Status</div>
            </div>
            <div class="row fw-normal pb-3">
                <div class="col-md-8 pe-0" v-if="visit && visit.schedule">
                    {{ formateDate(visit.schedule.start?.seconds) }} - {{ formateDate(visit.schedule.end?.seconds) }} <br/>
                        {{ Utility.getDuration(visit.schedule.slotDates?.start?.seconds, visit.schedule.slotDates?.end?.seconds) + ' days ' }}
                        <template v-if='visit.schedule.excludes && visit.schedule.excludes.length > 0'>
                            (excluding {{ visit.schedule.excludes.length }} days )
                        </template>
                        
                </div>
                <div class="col-md-4 text-end">{{ status }}</div>
            </div>
            <hr>
            <div class="row  pt-3 fw-bold">
                <div class="col-md-4">Building</div>
                <div class="col-md-5">Covered Locations</div>
                <div class="col-md-3">Primary Contact</div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    {{ visit.visitLocationInfo?.buildingName }} ({{ visit.visitLocationInfo?.buildingId }})<br />{{
                       visit.visitLocationInfo?.buildingAddress.line_1 +
                                    ' ' +
                                    visit.visitLocationInfo?.buildingAddress.line_2 +
                                    ' ' +
                                    visit.visitLocationInfo?.buildingAddress.city +
                                    ', ' +
                                    visit.visitLocationInfo?.buildingAddress.state +
                                    ' ' +
                                    visit.visitLocationInfo?.buildingAddress.zip
                    }}
                </div>
                <div class="col-md-5 overflow-auto" :style="{ maxHeight: '8.5vw' }">
                    <template v-for="location in visit.coveredLocations" :key="location.locationId">
                        <div class="row">
                            <div class="col-md-12">
                                <Button
                                    :icon="location.expand ? 'pi pi-minus' : 'pi pi-plus'"
                                    class="p-button-rounded p-button-text"
                                    @click="onExpand(location)"
                                />
                                <div class="d-inline-flex pb-1">{{ location.locationName }} ({{ location.locationId }})</div>
                            </div>
                        </div>
                        <div class="row" v-show="location.expand">
                            <small class="col-md-12 ps-5 pe-0 text-sm text-muted">
                                SPOC: {{ location.spoc.name }} ({{ location.spoc.email }}, {{ location.spoc.phone }})
                            </small>
                        </div>
                    </template>
                </div>
                <div class="col-md-3" v-if="visit.primaryContact">
                    {{ visit.primaryContact.name }}<br />{{ visit.primaryContact.email }}<br />{{ visit.primaryContact.phone }}
                </div>
            </div>
            <br/>
            <div class="row pt-3 fw-bold">
                <div class="col-md-4">Visiting Teams ({{ visit.teams?.length }})</div>
                <div class="col-md-5">Information</div>
            </div>
            <div class="row">
                <div class="col-md-4 overflow-auto" :style="{ maxHeight: '9vw'}">
                    <ul v-if="visit.teams" class="list-unstyled">
                        <li  class="d-flex" v-for="team in visit.teams" :key="team.teamId">
                            <Button
                                :icon="team.expand ? 'pi pi-minus' : 'pi pi-plus'"
                                class="p-button-rounded p-button-text"
                                @click="onExpand(team)"
                            />
                            <div class="w-100">
                                <div class="d-inline-flex text-wrap text-break pt-1" style="justify-content: flex-start;">
                                    {{ team.name }}
                                </div>
                                <ul v-show="team.expand" class="list-unstyled ps-2">
                                    <li>{{ team.team_lead.name }} (Lead)</li>
                                    <li v-for="member in team.members" :key="member.id">
                                        {{ member.name }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-5 overflow-auto" :style="{ maxHeight: '9vw' }">
                    <ul v-if="visit.notifications" class="list-unstyled">
                        <li v-for="notification in visit.notifications.slice().reverse()" :key="notification.notificationId">
                            {{ formateDate(notification.when.seconds) }}: {{ notification.desc }}
                        </li>
                    </ul>
                    <div v-html="this.$sanitize(visit.instructions)" class="instruction"></div>
                    <ul v-if="visit.documents" class="list-unstyled">
                        <li v-for="document in visit.documents" :key="document.documentId">
                            <a :href="document.link" download target="_blank" rel="noopener noreferrer">{{ document.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <template #footer>
            <hr>
            <Button label="Ok" @click="$emit('toggelModel')" class="d-inline">OK</Button>
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Utility from '../../../../shared/utility/utility';
import { VisitStatus } from '../../../../shared/enums';

export default {
    name: 'EditVisit',
    data() {
        return {
            Utility,
            visit: { ...this.visitById, building: {} },
            showModel: true
        };
    },
    components: {
        Button,
        Dialog
    },
    props: {
        visitById: Object
    },
    computed: {
        status() {
            return Object.keys(VisitStatus).find(id => VisitStatus[id] === this.visit.state);
        }
    },
    methods: {
        formateDate(date) {
            return Utility.formateDate(date, 'ddd, M/D/YY');
        },
        onExpand(data) {
            data.expand = !data.expand;
        }
    }
};
</script>
<style scoped>
.instruction:deep(ul){
    padding-left: 1rem;
}
</style>
