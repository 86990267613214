<template>
    <Dialog :visible="true" modal="true" :closable="false" :style="{ width: '80vw' }">
        <template #header>
            <div class="d-flex justify-content-between w-100">
                <span class="fw-bolder">
                <h4>{{visitData ? 'Edit Visit' : 'CreateVisit'}}</h4>
               </span>
               <div v-if="visitData" class="me-3">
                    <Button v-if="(visitData.state==VisitStatus.Draft)" type="submit" label="START"
                    @click="onStatusClick(VisitStatus.Scheduled)" />
                    <Button v-if="(visitData.state==VisitStatus.Scheduled)" label="STOP"
                    @click="onStatusClick(VisitStatus.Completed)" class="p-button-outlined m-1" />
                    <Button v-if="!hasVisitActivity" label="DISCARD"
                    @click="onStatusClick(VisitStatus.Cancelled)" class="p-button-danger m-1" />
                </div>
            </div>
        </template>
        <form>
            <div class="container container-width mt-2">
                <div class="row">
                    <div :class="visitData ? 'col-md-7' : 'col-md-12'">
                        <label class="fw-bold">Visit Title <span class="text-danger ms-1">*</span></label>
                        <input id="visitTitle" v-model.lazy="v$.visit.title.$model" type="text"
                            class="w-100 p-inputtext-sm form-control p-2" placeholder="Visit" />
                        <span v-if="v$.visit.title.$error && v$.visit.title.required && v$.visit.title.required.$invalid"
                            class="text-danger"> {{ messages.visitValidation.visitNameErrorMessage }}
                        </span>
                        <span v-else-if="v$.visit.title.$error && v$.visit.title.asyncValidator &&
                                v$.visit.title.asyncValidator.$invalid && visit.title" class="text-danger">
                            {{ messages.visitValidation.visitNameExist }}
                        </span>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="fw-bold">Borough<span class="text-danger ms-1">*</span></label>
                                <Dropdown id="ddBorough" :options="boroughs" v-model="v$.selectedBorough.$model"
                                    dataKey="id" :disabled="visitData" optionLabel="name" placeholder="Select Borough"
                                    class="w-100" :filter="enableDropDownFilter" filterPlaceholder="Search"
                                    @change="onChangeDropdown('borough')">
                                </Dropdown>
                                <span v-if="v$.selectedBorough.$error && v$.selectedBorough.mustHaveId &&
                                    v$.selectedBorough.mustHaveId.$invalid" class="text-danger">
                                    {{ messages.visitValidation.boroughErrorMessage }}
                                </span>
                            </div>
                            <div class="col-md-3">
                                <label class="fw-bold">District <span class="text-danger ms-1">*</span></label>
                                <Dropdown id="ddDistrict" :options="districts" dataKey="name" :disabled="visitData"
                                    v-model="v$.selectedDistrict.$model" optionLabel="name" placeholder="Select District" class="w-100"
                                    :filter="enableDropDownFilter" filterPlaceholder="Search" @change="onChangeDropdown('district')" >
                                    <template #loader>
                                        <div class="flex align-items-center p-2" style="height: 38px">
                                            <Skeleton width="60%" height="1rem" />
                                        </div>
                                    </template>
                                </Dropdown>
                                <span v-if="v$.selectedDistrict.$error && v$.selectedDistrict.mustHaveId &&
                                        v$.selectedDistrict.mustHaveId.$invalid" class="text-danger">
                                    {{ messages.visitValidation.districtErrorMessage }}
                                </span>
                            </div>
                            <div class="col-md-3">
                                <label class="fw-bold">Visit Location <span class="text-danger ms-1">*</span></label>
                                <Dropdown id="ddVisitLocation" :options="visitLocations" dataKey="id" :disabled="visitData"
                                    v-model="v$.selectedVisitLocation.$model" optionLabel="name" placeholder="Select Visit Location"
                                    class="w-100" @change="onChangeVisitLocation" panelClass="dropdown-panel" />
                                <span v-if="v$.selectedVisitLocation.$error && v$.selectedVisitLocation.mustHaveId &&
                                    v$.selectedVisitLocation.mustHaveId.$invalid" class="text-danger">
                                    {{ messages.visitValidation.visitLocationErrorMessage }}
                                </span>
                            </div>
                            <div class="col-md-3">
                                <label class="fw-bold">Purpose</label>
                                <Dropdown id="ddSlotType" :options="selectedVisitLocation?.purposeList" v-model="selectedSlotType"
                                    dataKey="purposeId" :disabled="visitData" optionLabel="name" placeholder="Select Purpose"
                                    class="w-100" @change="onChangeSlotType" />
                            </div>
                            <span v-if="this.duplicateVisitForVisitLocationErrorMessage" class="text-danger mt-1">
                                {{this.duplicateVisitForVisitLocationErrorMessage}}
                            </span>
                        </div>
                        <div class="row mt-4 pt-1">
                            <div class="col-md-12">
                                <label class="fw-bold">Address</label><br />
                                <label id="address" v-if="selectedVisitLocation?.buildingAddress &&
                                    !(selectedVisitLocation?.buildingAddress.line_1 === undefined)">
                                    {{ selectedVisitLocation.buildingAddress.line_1 + ' ' +
                                        selectedVisitLocation.buildingAddress.line_2 + ' ' +
                                        selectedVisitLocation.buildingAddress.city + ', ' +
                                        selectedVisitLocation.buildingAddress.state + ' ' +
                                        selectedVisitLocation.buildingAddress.zip }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="fw-bold">Covered Locations</label>
                        <div v-if="(!visitData)" class="row  max-height-10">
                            <CoveredLocations :coveredLocations="selectedVisitLocation?.coveredLocations" />
                        </div>
                        <div v-else class="row  max-height-10">
                            <CoveredLocations :coveredLocations="visitData?.coveredLocations" />
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 pe-0">
                        <div class="row">
                            <label class="fw-bold">Booked Slot</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <InputText id="bsTitle" type="text" v-model="slot.title"
                                    class="w-100 p-inputtext-sm" placeholder="Slot Title" disabled />
                            </div>
                        </div>
                        <div class="row mt-4 pt-1">
                            <div class="col-md-4">
                                <span class="p-float-label">
                                    <Calendar id="dsStart" v-model="schedule.slotDates.start" disabled />
                                    <label for="dsStart">Slot From</label>
                                </span>
                            </div>
                            <div class="col-md-4">
                                <span class="p-float-label">
                                    <Calendar id="dsEnd" v-model="schedule.slotDates.end" disabled />
                                    <label for="dsEnd">Slot upto</label>
                                </span>
                            </div>
                            <div class="col-md-4">
                                <InputText id="bbUser" v-model="slot.bookedBy" type="text" class="w-100 p-inputtext-sm"
                                    placeholder="Booked By" disabled />
                            </div>
                        </div>
                        <div class="row mt-4 pt-1">
                            <div class="col-md-4">
                                <span class="p-float-label">
                                    <Calendar id="dStart" v-model="schedule.start" :disabled="!schedule.start" @dateSelect="onDateSelect"
                                        :showTime="true" :showIcon="true" :minDate="schedule.slotDates.start"
                                        :maxDate="schedule.slotDates.end" />
                                    <label for="dStart">Visit From</label>
                                </span>
                            </div>
                            <div class="col-md-4">
                                <span class="p-float-label">
                                    <Calendar id="dEnd" v-model="schedule.end" :disabled="!schedule.end" @dateSelect="onDateSelect"
                                        :showTime="true" :showIcon="true" :minDate="schedule.start" :maxDate="schedule.slotDates.end" />
                                    <label for="dEnd">Visit Upto</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-12 fw-bold">Primary Contact <span class="text-danger ms-1">*</span></label>
                            <div class="row">
                                <div class="col-md-12">
                                    <InputText id="pcName" type="text" v-model="v$.visit.primaryContact.name.$model"
                                        class="w-100 p-inputtext-sm" placeholder="Name" />
                                    <span
                                        class="text-danger"
                                        v-if="v$.visit.primaryContact.name.$error && v$.visit.primaryContact.name.required &&
                                            v$.visit.primaryContact.name.required.$invalid">
                                            {{ messages.visitValidation.primaryContactNameErrorMessage }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-4 pt-1">
                                <div class="col-md-12">
                                    <InputText id="pcEmail" type="email" v-model="v$.visit.primaryContact.email.$model"
                                        class="w-100 p-inputtext-sm" placeholder="Email" />
                                    <span class="text-danger"
                                        v-if="v$.visit.primaryContact.email.$error && v$.visit.primaryContact.email.required &&
                                            v$.visit.primaryContact.email.required.$invalid">
                                        {{ messages.visitValidation.primaryContactEmailErrorMessage }}
                                    </span>
                                    <span class="text-danger"
                                        v-if="v$.visit.primaryContact.email.$error && v$.visit.primaryContact.email.email &&
                                            v$.visit.primaryContact.email.email.$invalid">
                                        {{ messages.visitValidation.primaryContactEmailInvalid }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-4 pt-1">
                                <div class="col-md-12">
                                    <InputMask id="pcPhone" type="tel" mask="+1-999-999-9999" v-model="v$.visit.primaryContact.phone.$model"
                                        class="w-100 p-inputtext-sm" placeholder="Phone" />
                                    <span class="text-danger" v-if="v$.visit.primaryContact.phone.$error &&
                                        v$.visit.primaryContact.phone.required && v$.visit.primaryContact.phone.required.$invalid">
                                        {{ messages.visitValidation.primaryContactPhoneErrorMessage }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 pe-0">
                        <div class="d-flex py-1">
                            <label class="flex-grow-1 fw-bold">Excludes</label>
                            <Button icon="pi pi-plus" title="Add date to exclude" class="p-button-text p-0" :disabled="!schedule.end"
                                @click="onExcludeDateClick(true)" />
                        </div>
                        <div class="max-height-10">
                            <div class="d-flex py-1" v-for="(data, index) in popup.excludeDates.data" :key="index">
                                <div class="flex-grow-1 text-wrap text-break">{{ data.name }}</div>
                                <Button icon="pi pi-minus" title="Delete exclude date" class="p-button-text p-0" :disabled="data.disabled"
                                    @click="popup.excludeDates.data.splice(index, 1)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="fw-bold">Instructions</label>
                        <Editor v-model="visit.instructions" editorStyle="height: 6rem" />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 pe-0">
                        <div class="d-flex py-1">
                            <label class="flex-grow-1 fw-bold">Teams</label>
                            <Button icon="pi pi-plus" title="Select teams" class="p-button-text p-0" @click="onPickItemClick('teams')" />
                        </div>
                        <div class="max-height-10">
                            <div class="d-flex py-1" v-for="(data, index) in popup.teams.data" :key="index">
                                <div role="button" class="text-wrap text-break flex-grow-1"
                                    :style="selectedTeam.teamId == data.teamId ? 'background: #00B0F0;' : ''"
                                    @click="onTeamClick(index)"> {{ data.name }} </div>
                                <Button icon="pi pi-minus" title="Delete team" class="p-button-text p-0"
                                    :disabled="data.disabled" @click="onTeamDelete(index)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" v-show="popup.users.data.length">
                        <div class="d-flex">
                            <div class="flex-grow-1 text-truncate fw-bold">{{ selectedTeam.name }}'s Members</div>
                            <Button icon="pi pi-plus" title="Select members" class="p-button-text p-0" @click="displayMemberPopupMethod" />
                        </div>
                        <div class="max-height-10">
                            <div class="d-flex py-1" v-for="(data, index) in popup.users.data" :key="index">
                                <div class="flex-grow-1 text-wrap text-break">{{ data.name }}</div>
                                <Button icon="pi pi-minus" title="Delete member" class="p-button-text p-0"
                                    :disabled="data.disabled" @click="popup.users.data.splice(index, 1)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12" v-show="false">
                        <label class="fw-bold">Notifications</label>
                        <Button icon="pi pi-plus" title="Add notifications" class="float-end p-button-text" />
                    </div>
                    <div class="col-md-6 pe-0">
                        <div class="d-flex py-1">
                            <label class="flex-grow-1 fw-bold">Documents</label>
                            <Button icon="pi pi-plus" title="Select Documents" class="p-button-text p-0"
                                @click="onPickItemClick('documents')"/>
                        </div>
                        <div class="max-height-10">
                            <div class="d-flex py-1" v-for="(data, index) in popup.documents.data" :key="index">
                                <div class="flex-grow-1 text-wrap text-break">
                                    <a v-if="data.link" ref="downLoadLink" :href="data.link" download
                                        target="_blank" rel="noopener noreferrer"> {{ data.name }}</a>
                                    <template v-else> {{ data.name }} </template>
                                </div>
                                <Button icon="pi pi-minus" title="Delete team" class="p-button-text p-0"
                                    :disabled="data.disabled" @click="popup.documents.data.splice(index, 1)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <MultiSelect
                    :dataList="popup.dataList"
                    :header="popup[popup.opName].header"
                    v-if="popup.visible"
                    :visible="popup.visible"
                    :selectedItems="popup.selectedItems.bind(popup)"
                    :opName="popup.opName"
                    :loading="loading"
                    :dataKey="popup[popup.opName].dataKey"
                    @closePopup="this.popup.visible = false"
                    @selectionDone="onSelectionDone"
                />
                <UserSelection
                    :selectionMode="selectionMode"
                    v-if="displayMemberPopup"
                    @selectionDone="selectionDone"
                    @closePopup="displayMemberPopup = false"
                    :selectedMembers="this.selectedTeam.members"
                    :listRole="memberListRole"
                />
                <ProgressSpinner class="p-component-overlay position-fixed w-100 h-100" strokeWidth="8" v-if="spinner" />
            </div>
        </form>
        <template #footer class="mt-5">
            <hr />
            <Button v-if="(visitData && visitData.state==VisitStatus.Draft )" label="DELETE" @click="onDeleteClick"
            class="p-button-danger float-start me-3" />
            <Button type="submit" label="SAVE" @click="onSaveClick" :disabled="disableSaveBtn"/>
            <Button label="CANCEL" @click="$emit('toggelModal')" class="me-3 p-button-outlined" />
        </template>
    </Dialog>
    <ConfirmDialog group="cancelStatusDialog" style=" width: 40vw; white-space: pre-line "
    @hide="closeSpinnerAllowSaveButton()"></ConfirmDialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';
import { CoveredLocations, MultiSelect } from '../shared';
import { serviceFactory, SlotService, VisitService, VisitLocationService, VisitActivityService } from '../../../../apis';
import { toasterTime, messages, userActivities, toasterType } from '../../../../shared/constants/constants';
import { Status } from '../../../../shared/enums';
import Utility from '../../../../shared/utility/utility';
import { Timestamp } from '../../../../../firebase/functions';
import { memberListRole } from '../../../../shared/constants/service-constants';
import { VisitStatus } from '../../../../shared/enums/visit-status';
import UserSelection from '../../../../shared/popup/UserSelection.vue';
import { UserActivity } from '../../../../shared/utils';
import _cloneDeep from 'lodash/cloneDeep';
import ConfirmDialog from 'primevue/confirmdialog';
const { withAsync } = helpers;
const mustHaveKey = param => value => value && value[param];

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'CreateVisit',
    components: {
        Dialog,
        Button,
        Calendar,
        ConfirmDialog,
        Dropdown,
        Editor,
        InputText,
        InputMask,
        MultiSelect,
        ProgressSpinner,
        Skeleton,
        CoveredLocations,
        UserSelection
    },
    props: {
        visitData: {
            type: Object,
            default: () => ({})
        },
        boroughs: Array,
        districtList: Array
    },
    data() {
        const slotTypeReset = {
            'slot': {},
            'schedule': {
                slotDates: {}
            },
            'visit.schedule': {
                slotDates: {},
                excludes: []
            },
            'popup.excludeDates.data': []
        };
        const visitLocationReset = {
            'visit.primaryContact': {},
            'selectedSlotType': null,
            ...slotTypeReset
        };
        const districtReset = {
            visitLocations: [],
            selectedVisitLocation: {
                coveredLocations: [],
                buildingAddress: {}
            },
            ...visitLocationReset
        };
        return {
            hasVisitActivity: true,
            userActivities: userActivities,
            messages,
            memberListRole,
            selectionMode: null,
            displayMemberPopup: false,
            boroughErrorMessage: null,
            districtErrorMessage: null,
            visitLocationErrorMessage: null,
            duplicateVisitForVisitLocationErrorMessage: null,
            enableDropDownFilter: true,
            slot: {},
            VisitStatus,
            schedule: {
                slotDates: {}
            },
            popup: {
                visible: false,
                opName: '',
                dataList: [],
                excludeDates: {
                    dataKey: 'milliseconds',
                    data: [],
                    selectionMap: data => data.sort((a, b) => a.milliseconds - b.milliseconds)
                },
                users: {
                    data: [],
                    filters: { role: { matchMode: 'in', value: memberListRole } },
                    header: 'Select members',
                    map: data => ({ id: data.id, name: data.name }),
                    selectionMap: this.appendTeamMember
                },
                documents: {
                    data: [],
                    dataKey: 'documentId',
                    filters: [],
                    header: 'Select documents',
                    map: data => ({
                        documentId: data.id,
                        name: data.name,
                        link: data.link,
                        type: data.type,
                        fileExtension: data.fileExtension,
                        size: data.size
                    })
                },
                teams: {
                    data: [],
                    dataKey: 'teamId',
                    filters: [],
                    header: 'Select teams',
                    map: data => ({
                        teamId: data.id,
                        name: data.name,
                        team_lead: { id: data.team_lead.id, name: data.team_lead.name },
                        members: data.members
                    })
                },
                selectedItems() {
                    return this[this.opName].data;
                }
            },
            action: {
                borough: {
                    reset: {
                        districts: [],
                        selectedDistrict: null,
                        ...districtReset
                    }
                },
                district: {
                    reset: districtReset
                },
                visitLocation: {
                    reset: visitLocationReset
                },
                slotTypes: {
                    reset: slotTypeReset
                }
            },
            selectedBorough: { id: this.visitData?.visitLocationInfo?.boroughId },
            selectedDistrict: { name: this.visitData?.visitLocationInfo?.districtName, id: this.visitData?.visitLocationInfo?.districtId },
            selectedVisitLocation: { id: this.visitData?.visitLocationId, coveredLocations: [], buildingAddress: {} },
            selectedSlotType: { purposeId: this.visitData?.purposeId },
            selectedTeam: {},
            districts: [],
            visitLocations: [],
            loading: false,
            spinner: false,
            disableSaveBtn: false,
            visit: {
                state: 0,
                primaryContact: {},
                schedule: {
                    slotDates: {},
                    excludes: []
                },
                documents: [],
                ...this.visitData
            },
            flagAvoidSuccessMessage: 0
        };
    },
    validations() {
        return {
            visit: {
                title: { required, asyncValidator: withAsync(this.checkVisitNameExist) },
                primaryContact: {
                    name: { required },
                    email: { required, email },
                    phone: { required }
                }
            },
            selectedBorough: { mustHaveId: mustHaveKey('id') },
            selectedDistrict: { mustHaveId: mustHaveKey('id') },
            selectedVisitLocation: { mustHaveId: mustHaveKey('id') }
        };
    },
    async mounted() {
        if (this.visitData) {
            if (this.visitData.state == VisitStatus.Scheduled) {
                this.hasVisitActivity = await VisitActivityService.getById(this.visitData.id);
            }
            this.getDistricts();
            await this.getVisitLocations();
            await this.getSlotType();
            this.popup.teams.data = this.visitData.teams;
            this.popup.documents.data = this.visitData.documents;
            if (!this.visitData.schedule.start) {
                this.flagAvoidSuccessMessage = 1;
                await this.onSaveClick();
            }
        }
    },
    methods: {
        async onStatusClick(status) {
            if ( status == VisitStatus.Cancelled && this.visitData?.state != VisitStatus.Cancelled) {
                this.$confirm.require({
                    group: 'cancelStatusDialog',
                    message: messages.visitValidation.cancelStateConfirmation.message,
                    header: messages.visitValidation.cancelStateConfirmation.header,
                    accept: async ()=> {
                        this.visit.state = status;
                        await this.onSaveClick();
                    },
                    reject: ()=> {
                        this.closeSpinnerAllowSaveButton();
                    },
                    acceptClass: 'delete-popup-accept-btn',
                    rejectClass: 'p-button-outlined'
                });
            }
            if (status != VisitStatus.Cancelled) {
                this.visit.state = status;
                await this.onSaveClick();
            }
        },
        displayMemberPopupMethod() {
            this.selectionMode = 'multiple';
            this.displayMemberPopup = true;
        },
        selectionDone(data) {
            this.displayMemberPopup = false;
            this.popup.users.data = [];
            Object.keys(data).forEach(key => {
                const obj = {
                    id: data[key].id,
                    name: data[key].name
                };
                if (data[key].disabled) {
                    obj.disabled = data[key].disabled;
                }
                this.popup.users.data.push(obj);
            });
            this.selectedTeam.members = this.popup.users.data;
        },
        async checkVisitNameExist() {
            if (this.visit.title?.trim()) {
                const tempVisitName = Utility.removeInBetweenSpaces(this.visit.title).trim();
                const value = await VisitService.checkVisitNameExist(tempVisitName, this.visitData ? this.visitData.id : 0);
                return value ? false : true;
            }
            return false;
        },
        getDistricts() {
            this.districts = this.districtList.filter(x => x.boroughId == this.selectedBorough.id);
        },
        async getVisitLocations() {
            try {
                if (!this.selectedBorough?.id || !this.selectedDistrict?.id) return;
                this.loading = true;
                this.visitLocations = await VisitLocationService.getAll({
                    boroughId: { matchMode: '==', value: this.selectedBorough?.id },
                    districtId: { matchMode: '==', value: this.selectedDistrict?.id },
                    state: { matchMode: '==', value: Status.Active }
                });
                if (this.selectedVisitLocation?.id) {
                    this.selectedVisitLocation = this.visitLocations.find(x => x.id === this.selectedVisitLocation?.id);
                    this.selectedSlotType = this.selectedVisitLocation?.purposeList?.find(
                        x => x.purposeId === this.selectedSlotType?.purposeId);
                }
            } catch (error) {
                this.showToaster(messages.visitValidation.visitLocationFetchingFailed, toasterType.error);
            }
        },
        async getSlotType(reset) {
            try {
                const filter = {
                    'booking.visitLocationId': { matchMode: '==', value: this.selectedVisitLocation?.id },
                    'booking.purposeId': { matchMode: '==', value: this.selectedSlotType?.purposeId },
                    'schedule.days': { matchMode: '==', value: this.selectedSlotType?.duration },
                    'state': { matchMode: '==', value: Status.Active }
                };
                this.loading = true;
                const slotTypes = await SlotService.getAll(filter, 1);
                slotTypes.forEach(({ id, title, schedule, booking }) => {
                    this.slot = { title, bookedBy: booking.by?.name };
                    const { start, end } = schedule;
                    if (!this.visitData || reset) {
                        this.visit.schedule = {
                            slotId: id,
                            start,
                            end,
                            slotDates: {
                                start,
                                end
                            }
                        };
                        this.schedule = {
                            start: Utility.convertSecondsToDateTime(start.seconds),
                            end: Utility.convertSecondsToDateTime(end.seconds)
                        };
                        this.schedule.slotDates = {
                            ...this.schedule
                        };
                    } else if (this.visitData.schedule.start) {
                        this.schedule = {
                            start: Utility.convertSecondsToDateTime(this.visitData.schedule.start.seconds),
                            end: Utility.convertSecondsToDateTime(this.visitData.schedule?.end?.seconds),
                            slotDates: {
                                start: Utility.convertSecondsToDateTime(this.visitData.schedule?.slotDates?.start?.seconds),
                                end: Utility.convertSecondsToDateTime(this.visitData.schedule?.slotDates?.end?.seconds)
                            }
                        };
                        this.visit.schedule = {
                            slotId: id,
                            start: this.visitData.schedule.start,
                            end: this.visitData.schedule.end,
                            slotDates: {
                                start: this.schedule.slotDates.start,
                                end: this.schedule.slotDates.end
                            }
                        };
                    } else {
                        this.schedule = {
                            start: Utility.convertSecondsToDateTime(start.seconds),
                            end: Utility.convertSecondsToDateTime(end.seconds),
                            slotDates: {
                                start: Utility.convertSecondsToDateTime(start.seconds),
                                end: Utility.convertSecondsToDateTime(end.seconds)
                            }
                        };
                        this.visit.schedule = {
                            slotId: id,
                            start: start,
                            end: end,
                            slotDates: {
                                start: start,
                                end: end
                            }
                        };
                    }
                });
                this.onExcludeDateClick(false, true);
            } catch (error) {
                this.showToaster(messages.visitValidation.slotsFetchingFailed, toasterType.error);
            }
        },
        async onChangeDropdown(actionName) {
            try {
                const reset = this.action[actionName].reset;
                const resetObj = _cloneDeep(reset);
                Object.keys(resetObj).forEach(k => {
                    if (k.indexOf('.') < 0) {
                        this[k] = resetObj[k];
                    } else {
                        const np = k.split('.');
                        np.length == 2 && (this[np[0]][np[1]] = resetObj[k]);
                        np.length == 3 && (this[np[0]][np[1]][np[2]] = resetObj[k]);
                    }
                });
                if (actionName === 'borough' && this.selectedBorough.id !== undefined) {
                    this.boroughErrorMessage = null;
                    this.duplicateVisitForVisitLocationErrorMessage = null;
                    this.getDistricts();
                } else if (actionName === 'district' && this.selectedDistrict.id !== undefined) {
                    this.districtErrorMessage = null;
                    this.duplicateVisitForVisitLocationErrorMessage = null;
                    await this.getVisitLocations();
                } else if ( actionName === 'visitLocation' && (this.selectedVisitLocation?.coveredLocations.length ||
                    Object.keys(this.selectedVisitLocation?.buildingAddress).length != 0)) {
                    this.visitLocationErrorMessage = null;
                    this.duplicateVisitForVisitLocationErrorMessage = null;
                }
            } catch (err) {
                this.showToaster(messages.visitValidation.preparingVisitDataErrored, toasterType.error);
            }
        },
        async onChangeVisitLocation({ value }) {
            this.onChangeDropdown('visitLocation');
            if (!value) return;
            const {
                id,
                boroughId,
                boroughName,
                buildingId,
                buildingName,
                districtId,
                districtName,
                coveredLocations,
                buildingAddress,
                buildingMapLink,
                primaryContact
            } = value;
            this.visit.visitLocationId = id;
            this.visit.coveredLocations = coveredLocations;
            this.visit.visitLocationInfo = {
                boroughId,
                boroughName,
                buildingId,
                buildingName,
                districtId,
                districtName,
                buildingAddress,
                buildingMapLink
            };
            this.visit.primaryContact = primaryContact;
            this.selectedVisitLocation.buildingAddress = buildingAddress;
            if (this.selectedVisitLocation?.purposeList.length === 1) {
                this.selectedSlotType = this.selectedVisitLocation.purposeList[0];
                await this.onChangeSlotType(value);
            }
        },
        async onChangeSlotType(data) {
            await this.onChangeDropdown('slotTypes');
            await this.getSlotType(true);
            const { purposeId } = data.value ? data.value : data.purposeList[0];
            this.duplicateVisitForVisitLocationErrorMessage = null;
            this.visit.purposeId = purposeId;
        },
        onDateSelect() {
            this.visit.schedule.start = Timestamp.fromDate(this.schedule.start);
            this.visit.schedule.end = Timestamp.fromDate(this.schedule.end);
            this.onExcludeDateClick(false, true);
        },
        onSelectionDone({ name, data }) {
            this.popup.visible = false;
            this.popup[name].selectionMap?.call(this, data);
            this.popup[name].data = [...data];
        },
        appendTeamMember(members) {
            const memberMap = new Map();
            members.forEach(m => memberMap.set(m.id, m));
            this.selectedTeam.members = Array.from(memberMap.values());
            return this.selectedTeam.members;
        },
        onTeamClick(index) {
            const team = this.popup.teams.data[index];
            this.selectedTeam = team;
            this.popup.users.data = this.appendTeamMember([{ ...team.team_lead, disabled: true }, ...team.members]);
        },
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async onSaveClick() {
            let validationMessage;
            try {
                this.disableSaveBtn = true;
                const isFormValid = await this.v$.$validate();
                if (!isFormValid) {
                    this.disableSaveBtn = false;
                    return;
                } else {
                    validationMessage = messages.visitValidation.preparingVisitDataErrored;
                    if (!this.visitData && this.flagAvoidSuccessMessage == 0) {
                        const value = await VisitService.checkUniqueVisitLocationSlotTypeExist(
                            this.selectedBorough.id,
                            this.selectedDistrict.id,
                            this.selectedVisitLocation.id,
                            this.selectedSlotType.purposeId
                        );
                        if (value.some(x=> x.state === VisitStatus.Draft || x.state === VisitStatus.Scheduled )) {
                            this.duplicateVisitForVisitLocationErrorMessage =
                            messages.visitValidation.duplicateVisitExistForVisitLocationMessage;
                        }
                    }
                    if (this.duplicateVisitForVisitLocationErrorMessage) {
                        this.disableSaveBtn = false;
                        return;
                    }
                }
                this.spinner = true;
                const uniqueTeamMemberIds = new Set();
                // for making unique team members array
                this.popup.teams.data.forEach(t => {
                    t.team_lead?.id && uniqueTeamMemberIds.add(t.team_lead.id);
                    t.members?.slice(0).forEach((m, i) => {
                        m.disabled && t.members.splice(i, 1);
                        m.id && uniqueTeamMemberIds.add(m.id);
                    });
                });
                const visit = {
                    ...this.visit,
                    schedule: {
                        ...this.visit.schedule,
                        excludes: this.popup.excludeDates.data.map(({ milliseconds }) => Timestamp.fromMillis(milliseconds))
                    },
                    documents: this.popup.documents.data,
                    teams: this.popup.teams.data,
                    uniqueTeamMembers: [...uniqueTeamMemberIds]
                };
                visit.title = Utility.removeInBetweenSpaces(visit.title).trim();
                if (this.visitData) {
                    validationMessage = messages.visitValidation.visitEditedFailed;
                    await this.updateVisit(visit);
                    this.showToaster(messages.visitValidation.visitEditedSuccess, toasterType.success);
                } else {
                    const visitData = {
                        ...visit,
                        info: {
                            ...UserActivity.getActivity(userActivities.created),
                            ...UserActivity.getActivity(userActivities.updated)
                        }
                    };
                    validationMessage = messages.visitValidation.visitCreateFailed;
                    await VisitService.create(visitData);
                    this.showToaster(messages.visitValidation.visitCretedSuccess, toasterType.success);
                    this.$emit('toggelModal');
                }
            } catch (error) {
                this.showToaster(validationMessage, toasterType.error);
            } finally {
                this.closeSpinnerAllowSaveButton();
            }
        },
        async updateVisit(visit) {
            const visitData = {
                ...visit,
                info: {
                    ...UserActivity.getActivity(userActivities.updated),
                    ...UserActivity.getActivity(userActivities.cancelled, this.visit.state == VisitStatus.Cancelled),
                    ...UserActivity.getActivity(userActivities.completed, this.visit.state == VisitStatus.Completed)
                }
            };
            await VisitService.update(this.visitData.id, visitData);
            if (this.flagAvoidSuccessMessage == 0) {
                this.$emit('toggelModal');
            } else {
                this.flagAvoidSuccessMessage = 0;
            }
        },
        async checkVisitBooked() {
            const slotData = await SlotService.getById(this.visitData.schedule.slotId);
            return slotData?.booking ? true : false;
        },
        async onDeleteClick() {
            try {
                const res = await this.checkVisitBooked();
                if (res && this.visitData.state == VisitStatus.Draft) {
                    this.$confirm.require({
                        message: messages.visitValidation.deleteConfirmation.message,
                        header: messages.visitValidation.deleteConfirmation.header,
                        accept: async () => {
                            await VisitService.delete(this.visitData.id);
                            this.showToaster(messages.visitValidation.visitDeleteSuccess, toasterType.success);
                            this.$emit('toggelModal');
                        },
                        acceptClass: 'delete-popup-accept-btn',
                        rejectClass: 'p-button-outlined'
                    });
                } else {
                    this.showToaster(messages.visitValidation.bookedVisitCannotDelete, toasterType.error);
                }
            } catch (err) {
                this.showToaster(messages.visitValidation.visitDeleteFailed, toasterType.error);
            } finally {
                this.spinner = false;
            }
        },
        onExcludeDateClick(visible, isReset) {
            const opName = 'excludeDates';
            this.popup.visible = visible;
            if (!isReset && this.popup.opName == opName) return;
            this.popup.dataList = [];
            this.popup.opName = opName;
            let localData = [];
            let selectedDate = [];

            // removed the timestamp values from visit from and to dates to consider the case when user wants to include visit start and end date into excludes
            const startSeconds = Utility.convertSecondsToMoment(this.visit?.schedule?.start?.seconds)?.startOf('day').valueOf() / 1000;
            const endSeconds = Utility.convertSecondsToMoment(this.visit?.schedule?.end?.seconds)?.startOf('day').valueOf() / 1000;

            const date = Utility.addInDate(-1, 'd', Utility.convertSecondsToMoment(startSeconds));
            const endDate = Utility.addInDate(-1, 'd', Utility.convertSecondsToMoment(endSeconds));
            const res = Utility.getExcludedDate(date, endDate);
            localData = res.localData;
            selectedDate = res.selectedDate;

            if (this.visitData?.schedule.excludes.length) {
                this.visitData.schedule.excludes.forEach(({ seconds }) => {
                    const date = Utility.convertSecondsToMoment(seconds);
                    if (date.isoWeekday() < 6 && startSeconds <= seconds && endSeconds >= seconds) {
                        selectedDate.push({
                            name: Utility.formateDate(date, 'dddd, MM/D/YYYY'),
                            milliseconds: date.startOf('day').valueOf()
                        });
                    }
                });
                this.popup[opName].selectionMap(selectedDate);
            }
            this.popup[opName].data = selectedDate;
            this.popup.dataList = localData;
        },
        async onPickItemClick(opName) {
            this.popup.visible = true;
            if (this.popup.opName == opName && opName != 'documents') return;
            this.popup.dataList = [];
            this.popup.opName = opName;
            const filters = { state: { matchMode: '==', value: Status.Active }, ...this.popup[opName].filters };
            let teams = await serviceFactory(opName).getAll(filters);
            if (opName == 'documents') {
                if (this.selectedVisitLocation && this.selectedVisitLocation?.coveredLocations?.length) {
                    const locations = [];
                    this.selectedVisitLocation.coveredLocations.forEach(location => {
                        locations.push(...teams.filter(document => teams.indexOf(location.locationId) == -1 &&
                        ( document.associations.locations.length == 0 ||
                        document.associations.locations.indexOf(location.locationId) != -1)));
                    });
                    teams = [...new Set(locations)];
                } else if (this.selectedDistrict && this.selectedDistrict.id) {
                    teams = teams.filter(document => document.associations.districts.length == 0 ||
                    document.associations.districts.indexOf(this.selectedDistrict.id) != -1);
                } else if (this.selectedBorough && this.selectedBorough.id) {
                    teams = teams.filter(document => document.associations.boroughs.length == 0 ||
                    document.associations.boroughs.indexOf(this.selectedBorough.id) != -1);
                }
            }
            this.popup.dataList = teams.map(this.popup[opName].map);
            this.loading = false;
        },
        onTeamDelete(index) {
            this.popup.teams.data.splice(index, 1);
            this.popup.users.data = [];
        },
        closeSpinnerAllowSaveButton() {
            this.spinner = this.disableSaveBtn = false;
        }
    },
    emit: ['toggelModal']
};
</script>
<style>
.p-dropdown-panel.dropdown-panel {
    width: 12.7vw !important;
}
</style>
<style scoped>
.max-height-10 {
    max-height: 10.5rem;
    overflow: auto;
}
</style>
<style>
.p-progress-spinner-svg {
    width: 6.25rem !important;
}
.p-dropdown-filter-container{
    width: 10.313rem !important;
}
</style>
